// start content

.ui-autocomplete {
    width: 300px !important;
    background: #FFFFFF;
    font-weight: normal;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    list-style:none;
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 6px 10px;
    z-index: 1100;
}
.ui-autocomplete li {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 200%;
    color: #898F9E;
    font-family: Arial, monospace;
}
.ui-autocomplete li b, .ui-autocomplete li span {
    color: #007bff !important;
}